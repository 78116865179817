import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token && isTokenExpired(token)) {
        localStorage.clear();
        navigate('/');
      }
    };

    checkTokenExpiration();
  }, [navigate, token]);

  return children;
};

export const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert current time to seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Invalid token:", error);
    return true; // Treat invalid token as expired
  }
};

export default AuthWrapper;


