import React, { useState } from 'react';
import WorldCloud from './chartTypes/WordCloud';
import Grid from '@mui/material/Grid';
import { baseUrl } from '../../constants/baseUrl';
import './styles.css'
import QrcodeCp from './Qrcomp';

const Result = (props) => {
  const [question, setQuest] = useState("")

  
  return (
    <div style={{ padding: '20px 30px' }}>
      <div>
        <p style={{ fontSize: "1.9rem", textAlign: "center", margin: 0, fontWeight: "bold" }}>
          {question[0]?.title}
        </p>
        <p style={{ fontSize: "1.7rem", textAlign: "center", marginTop: 10, marginBottom: "0px" }}>
          {question[0]?.text}
        </p>
        <div style={{ boxShadow: '0 0.5px 0px 0px rgba(0,0,0,0.1)', padding: '20px' }}></div>
      </div>
      {/* <Grid container spacing={3} style={{ paddingTop: '50px' }} >
        <Grid item xs={12} sm={12} md={4} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div >
            <div >
              <img src={props.qr} width="230px" />
              <QrcodeCp/>
              <p className='animated-text'>Poll URL: {baseUrl?.substring(0, baseUrl.indexOf('/', baseUrl.indexOf('//') + 2))}</p>
            </div>
          </div>
          <div style={{ height: '100px' }}>
            <img src={props.logo} style={{ height: '100%', objectFit: 'cover' }} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div >
          </div>
        </Grid>
      </Grid> */}
      <WorldCloud setQuest={setQuest} />

    </div>
  );
};

export default Result;
