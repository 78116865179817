import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Box, CircularProgress } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "./login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios"; 
import { baseUrl } from '../../constants/baseUrl';
import { useSnackbar, enqueueSnackbar } from 'notistack';

const Login = ({ logo }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const preventBackNavigation = () => {
      window.history.pushState(null, document.title, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, document.title, window.location.href);
      };
    };

    preventBackNavigation();

    return () => {
      window.onpopstate = null; 
    };
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
  
    if (!data.phone) {
      setError("phone", { type: "manual", message: "Phone number is required" });
      setLoading(false);
      return;
    }
  
    const phoneNumberDetails = parsePhoneNumber(data.phone);
    if (!phoneNumberDetails || !phoneNumberDetails.isValid()) {
      setError("phone", { type: "manual", message: "Enter a valid phone number" });
      setLoading(false);
      return;
    }
  
    const countryCode = `+${phoneNumberDetails.countryCallingCode}`;
    const nationalNumber = phoneNumberDetails.nationalNumber;
  
    if (nationalNumber.length !== 10) {
      setError("phone", { type: "manual", message: "Phone number must be exactly 10 digits" });
      setLoading(false);
      return;
    }
  
    try {
      const response = await fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone: {
            countryCode,
            number: nationalNumber,
          },
        }),
      });
  
      const result = await response.json();

      console.log("API Response:", result);
  
      if (response.ok) {
        setOtpSent(true);
        enqueueSnackbar("OTP sent successfully. Check your phone for the code.", { variant: 'success' });
        navigate(`/otp?number=${nationalNumber}`);
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar("Failed to send OTP. Please try again.", { variant: 'error' });
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <Box
      className="login-container"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // minHeight: "100vh",
        backgroundColor: "#f4f4f4",
        padding: '0 10px',
      }}
    >
      <Box
        className="login-box"
        sx={{
          backgroundColor: "white",
          padding: '20px',
          borderRadius: 2,
          boxShadow: 3,
          width: { xs: "90%", sm: "400px" },
        }}
      >
        <Box className="logo-containe" sx={{ textAlign: "center" }}>
          <img src={logo} alt="Logo" style={{ maxWidth: "120px", height: "auto" }} />
        </Box>

        <h2 className='otp' style={{ marginTop:'0'}}>
          Login
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className="phone-input-container">
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      international
                      defaultCountry="IN"
                      countryCallingCodeEditable={false}
                      value={value}
                      onChange={(phone) => {
                        clearErrors("phone");
                        onChange(phone);
                      }}
                      className="phone-input"
                      style={{ height: "45px" }}
                    />
                  )}
                />
              </Box>
              {errors.phone && <p className="error-message">{errors.phone.message}</p>}
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ height: "45px", fontSize: "16px", textTransform: "none", backgroundColor: "#195C76",
                '&:hover': {
                  backgroundColor: "#195C76",
                },

                 }}
                disabled={otpSent}
              >
                {loading ? <CircularProgress size={24} /> : otpSent ? "OTP Sent" : "Send OTP"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
