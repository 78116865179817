import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import UserInput from './userInput/UserInput';
import Result from './result/Result';
import Login from './auth/login';
import Otp from './auth/otp';
import { AuthWrapper } from './utils'; // Correct if it's a named export
import logo from '../images/riselogo.png'; // Consider renaming this file to avoid spaces
import qr from '../images/qr.png';
import { header } from '../constants/baseUrl';

const Display = () => {
  useEffect(() => {
    document.title = header || 'Default Title';
  }, []);
  

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<AuthWrapper><Login logo={logo} /></AuthWrapper>} />
      <Route path="/otp" element={<AuthWrapper><Otp /></AuthWrapper>} />
      <Route path="/question" element={<AuthWrapper><UserInput logo={logo} /></AuthWrapper>} />
      <Route path="/result" element={<Result qr={qr} logo={logo} />} />
    </Routes>
  </BrowserRouter>
  );
};

export default Display;
