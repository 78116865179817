import React, { useState, useEffect } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Button, Box, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import './login.css';
import { baseUrl } from '../../constants/baseUrl';
import { useSnackbar } from 'notistack';

const Otp = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  // Extract phone number from query params
  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get('number');

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!phoneNumber || !otp) {
      enqueueSnackbar('Phone number or OTP is missing.', { variant: 'error' });
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/otpverify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone: {
            countryCode: '+91',
            number: phoneNumber,
          },
          otp: otp,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to verify OTP');
      }

      const result = await response.json();

      if (result.data && result.data.userDetail) {
        localStorage.setItem('login', 'true');
        localStorage.setItem('token', result.data.token); // Assuming the token is under result.data.token
        localStorage.setItem('userId', result.data.userDetail.userId);

        enqueueSnackbar('OTP verified successfully', { variant: 'success' });
        navigate('/question');
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      enqueueSnackbar(error.message || 'Invalid OTP. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if ('OTPCredential' in window) {
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
        })
        .then((otp) => {
          console.log('OTP Retrieved:', otp.code);
          setOtp(otp.code);
        })
        .catch((err) => {
          console.error('Error retrieving OTP:', err);
        });
    } else {
      console.log('Web OTP API is not supported in this browser.');
    }
  }, []);

  return (
    <Box
      className="login-container"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f4f4f4',
      }}
    >
      <Box
        className="login-box"
        sx={{
          backgroundColor: 'white',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          width: '80%',
          maxWidth: '350px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <h2 className="otp">Enter OTP</h2>

        <MuiOtpInput
          value={otp}
          onChange={handleChange}
          className="otp-input"
          length={6}
          autoFocus
          separator="-"
          sx={{
            '& input': {
              textAlign: 'center',
              fontSize: '1rem',
              width: '0.5rem',
              height: '0.5rem',
              margin: '0.1rem',
            },
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={otp.length !== 6 || loading}
          sx={{
            marginTop: '1rem',
            width: '140px',
            height: '40px',
            backgroundColor: '#195C76',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#195C76',
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
};

export default Otp;
