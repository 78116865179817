import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { baseUrl, id } from '../../constants/baseUrl';
import './styles.css';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from 'react-router-dom';
import { useSnackbar, enqueueSnackbar } from 'notistack';




const UserInput = (props) => {
  const [inputText, setInputText] = useState('');
  const [textList, setTextList] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [quest, setQuest] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (event) => {
    const text = event.target.value;
    setInputText(text);
    setSubmitDisabled(text.trim() === '');
  };

  const value = localStorage.getItem('login'); 
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (!value) {
      navigate('/');
    }
  }, [value]);

  // const handleSubmit = () => {
  //   if (!submitDisabled) {
  //     fetch(`${baseUrl}/wordCloud`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "content-Type": "application/json"
  //       },
  //       body: JSON.stringify({ answer: [inputText], question: quest[0]?._id })
  //     })
  //       .then((res) => (res.json()))
  //       .then(() => {
  //         setTextList([...textList, inputText]);
  //         setInputText('');
  //         setSubmitDisabled(true);
  //       })
  //   }
  // };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    fetch(`${baseUrl}/poolquestions`)
      .then((res) => res.json())
      .then((resp) => {
        const filteredData = resp?.data?.filter((e) => e._id === id);
        setQuest(filteredData);
        setTextList(filteredData);
      });
  }, []);
  

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCheckboxChange = (option) => {
    setErrorMessage(""); // Clear error message on change
    setSelectedOptions((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else if (prev.length < 3) {
        return [...prev, option];
      } else {
        setErrorMessage("You can select a maximum of 3 options.");
        return prev;
      }
    });
  };

  const handleSubmit = async () => {
    if (selectedOptions.length > 3) {
      setErrorMessage("You can select a maximum of 3 options.");
      return;
    }
  
    try {
      const response = await fetch(`${baseUrl}/wordCloud`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",  
        },
        body: JSON.stringify({
          answer: selectedOptions,
          question: quest[0]?._id,  
          userId: userId,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to submit data");
      }
  
      const result = await response.json();
  
      if (result) {
        setSelectedOptions([]);  
        setErrorMessage("");  
        setSnackbarOpen(true); 
        localStorage.removeItem('login');  
        localStorage.removeItem('userId');  
        navigate('/');  
        enqueueSnackbar("Data submitted successfully!", { variant: "success" });
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMessage("Failed to submit data. Please try again.");
      enqueueSnackbar(error.message || "An error occurred. Please try again.", { variant: "error" });
    }
  };
  

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };




  return (
    <Grid container justifyContent="center" alignItems="center" className="container">
      <Grid item xs={10} md={6} lg={4}>
        <form>
        <div className="content">
          <img src={props.logo} className="logo" alt="Logo" style={{ maxWidth: "140px", height: "auto" }} />
          <p className="text" style={{marginTop: "0"}}>{quest[0]?.text}</p>
          <div
          className="scroll-container"
          style={{
            width: "70%",
            padding: "10px",
            margin: "0 auto",
          }}
        >
      <ol style={{ padding: "0", margin: "0" }}>
      {textList?.map((text, textIndex) =>
        text?.option?.map((option, optionIndex) => (
          <li
            key={`${textIndex}-${optionIndex}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              textTransform: "uppercase",
              lineHeight: "1.7",
            }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "10px", width: "20px", height: "20px", cursor: "pointer" }}
                value={option}
                onChange={() => handleCheckboxChange(option)}
                checked={selectedOptions.includes(option)}
              />
              <span style={{ fontSize: "1.2rem" }}>{option}</span>
            </label>
          </li>
        ))
      )}
    </ol>
    {errorMessage && (
        <div style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</div>
      )}
  </div>

      <Button
        style={{
          padding: "10px",
          backgroundColor: selectedOptions.length === 0 ? "#bdbdbd" : "#1976D2",
          color: "white",
          border: "none",
          cursor: selectedOptions.length === 0 ? "not-allowed" : "pointer",
        }}
        onClick={handleSubmit}
        disabled={selectedOptions.length === 0}
      >
        Submit
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          Submission successful!
        </Alert>
      </Snackbar>

          {/* <Grid>
            <div className='bottom'>
              <Paper
                component="form"
                elevation={2}
                sx={{
                  p: '2px 4px',
                  marginLeft: '5%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  outline: isHovered ? '2px solid #1976D2' : isFocused ? '2px solid #1976D2' : 'none',
                  transition: 'outline 0.3s',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <InputBase
                    sx={{
                      ml: 1,
                      flex: 1,
                      outline: 'none',
                      transition: 'outline 0.3s',
                    }}
                    inputProps={{  style: { outline: 'none', border: 'none' },maxLength:25 }}
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Button
                    variant='contained'
                    type="button"
                    sx={{ p: '10px', backgroundColor: submitDisabled ? '#bdbdbd' : isHovered ? '#1976D2' : 'primary' }}
                    aria-label="search"
                    onClick={handleSubmit}
                    disabled={submitDisabled}
                  >
                    <SendIcon color={submitDisabled ? 'disabled' : 'white'} />
                  </Button>
                </div>
              </Paper>
            </div>
          </Grid> */}
        </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default UserInput;
