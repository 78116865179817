import React, { useEffect, useRef, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import '../styles.css'
import { baseUrl, id, socketurl } from '../../../constants/baseUrl';
import io from "socket.io-client";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { Grid } from '@mui/material';


const WorldCloud = ({ setQuest, quest }) => {
  const wordCloudContainerRef = useRef(null);
  const [rotation, setRotation] = useState([0, 0]);
  const [wordCloudSize, setWordCloudSize] = useState([400, 400]);
  const [question, setQuestion] = useState('')
  const [words, setWords] = useState([])

  useEffect(() => {
    const updateSize = () => {
      if (wordCloudContainerRef.current) {
        const containerWidth = wordCloudContainerRef.current.offsetWidth;
        const containerHeight = wordCloudContainerRef.current.offsetHeight;
        const newSize = [containerWidth, containerHeight];
        if (window.innerWidth < 450) {
          setWordCloudSize([200, 400])
        } else {
          setWordCloudSize([400, 400])
        }
        const newRotation = containerWidth > containerHeight ? [0, 90] : [90, 0];
        setRotation(newRotation);
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  useEffect(() => {
    if (!question[0]?._id) return;
    const socket = io(socketurl);


    function onFooEvent(value) {
      //   setFooEvents(value);
      setWords(value?.map((e) => ({ text: e?.answer, value: e?.count })))
    }

    socket.on(question[0]?._id, onFooEvent);

    return () => {
      socket.off(question[0]?._id, onFooEvent);
    };
  }, [question[0]?._id]);

  useEffect(() => {
    fetch(`${baseUrl}/poolquestions`)
      .then((res) => res.json())
      .then((resp) => {
        setQuestion(resp?.data?.filter((e) => e._id == id))
        setQuest(resp?.data?.filter((e) => e._id == id));
        getAns(id)
      })
  }, [])
  const getAns = (id) => {
    fetch(`${baseUrl}/poolquestions/${id}/stats`)
      .then((res) => res.json())
      .then((res) => {
        setWords(res.data?.map((e) => ({ text: e?.answer, value: e?.count })))
      })
  }

  const handleWordClick = (word) => {
    const searchQuery = encodeURIComponent(word.text);
    const googleSearchURL = `https://www.google.com/search?q=${searchQuery}`;
    window.open(googleSearchURL, '_blank');
  };

  const callbacks = {
    onWordClick: handleWordClick,
    onWordMouseOver: console.log,
    getWordTooltip: (word) => `${word.text} (${word.value})`,
  };

  const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: true,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [20, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 2,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
    shape: "circle",
  };


  return (
  
    <div
    style={{
      marginTop: '20px',
      height: 'auto',
      borderRadius: '15px',
      overflowY: 'auto',
      fontFamily: "'Roboto', sans-serif",
    }}
    className="wordCloudContainer"
  >
  <div
  style={{
   maxWidth: '500px',
    margin: '20px auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  }}
  className="creative-list"
>
  {words.map((word, index) => (
    <div
      key={index}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '2px solid #195C76',
        borderRadius: '8px',
        padding: '15px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.3s ease',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <div
          style={{
            color: '#195C76',
          }}
        >
          {index + 1}.
        </div>
        <div
          style={{
            color: '#195C76',
            fontWeight: '500',
            fontSize: '16px',
            flex: 1,
          }}
        >
          {word.text}
        </div>
      </div>
      <div
        style={{
          color: '#195C76',
          fontWeight: '700',
          textAlign: 'right',
          fontSize: '16px',
        }}
      >
        {word.value} Vote
      </div>
    </div>
  ))}
</div>



      
    


      
     
     {/* <ReactWordcloud
        // size={wordCloudSize}
        callbacks={callbacks}
        options={options}
        words={words}
      /> */}
     
    </div>
  );
};


export default WorldCloud;
