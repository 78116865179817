import './App.css';
import Display from './components/Display';
// import logo from '../src/images/comorin logo.jpg'


function App() {
  
  return (
    <div className="App">
      <Display/>
    </div>
  );
}

export default App;
